.payment-details{
  
 
  height: 50rem;
  width: 35rem;
  z-index: 999;
  color: red;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #F0F0F0;
  background-color: white;
  box-shadow: 0px 0px 9px 0px rgb(0, 0, 0.2,0.2);
}



.title-head{
  font-family: Outfit;
  font-weight: bold;
  color: rgb(32, 32, 84);
  

}
.title-para{
  font-family: Outfit;
  font-weight: bold;
  color: rgb(32, 32, 84);
  font-size: 18px;
  text-align: left;
}