.mainpage{
  background-color: white; 
  height: 48rem;
  width: 85rem;
  margin-top: 5%;
  margin-bottom: 5%;
  opacity: 1;
  box-shadow: 0px 2px 8px 0px rgba(68, 3, 3, 0.27);
  border: 1px solid #d32028;
  border-radius: 10px;
  color: rgb(24, 24, 103);
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-grow: 0;
  flex-direction:row ;
}

.container{
 
  left: 1%;
  display: block;
  justify-content: center;
  align-items: center;
  width: 50rem;

  padding-bottom: 2rem;
}

.title-text{
  font-family: Outfit;
  font-size: 18px;
  width: 90%;
  text-align: center;
}